<template>
  <div class="wrap">
    <!-- <van-nav-bar title="查看医嘱" left-arrow @click-left="onClickLeft" /> -->
    <!-- <div style="padding: 16px 0 0 25px;font-weight: bold;font-size: 16px; ">{{dataList.name}}（{{ cardNo }}）</div> -->
    <div class="head_box">
      <div class="box_info">
        <div class="box_left">
          <img src="../../assets/images/head.png" style="width:40px;height:40px" />
          <div class="box_left1">
            <div class="left1_1">{{ name }}</div>
            <div class="left1_2">{{ cardNo }}</div>
          </div>
        </div>
        <img src="../../assets/images/head_right.png" style="width:46px;height:46px" />
      </div>
    </div>

    <div class="selectTime">
        <input
          type="text"
          class="leftInput"
          placeholder="请选择开始时间"
          v-model="startTime"
          @click="getStartTime"
        />
        <input
          type="text"
          placeholder="请选择结束时间"
          v-model="endTime"
          @click="getEndTime"
        />
        <div class="sureSelect" @click="sureSelectTime">确认</div>
      </div>

 <div class="box">
     <div
          class="content"
          @click="goDetail(item)"
          v-for="(item, i) in dataList"
          :key="i"
        >
          <div class="top dynamic_title">
            <div v-if="item.examItems" style="width: 78%">
              {{ item.examItems }}
            </div>
            <div v-if="item.subject" style="width: 78%">{{ item.subject }}</div>
            <div :class="item.resultStatus == 4?'out':'noout'">{{ resultInfo[item.resultStatus] }}</div>
          </div>
          <div class="bottom">
            <!-- <div class="bottom_content">
            <div style="font-size: 15px; color: #333333">医院</div>
            <div style="font-size: 14px; color: #666666">
              {{ hospitalName }}
            </div>
          </div> -->
          <div class="bottom_content">
              <div>开单医生</div>
              <div style="color:#2089F7">{{ item.requestPhysician }}</div>
            </div>
            <div class="bottom_content">
              <div>开单时间</div>
              <div>{{ item.requestDateTime }}</div>
            </div>
            <div class="bottom_content">
              <div>报告时间</div>
              <div>
                {{ item.reportDateTime }}
              </div>
            </div>
          </div>
        </div>
        <!-- 无预约数据时显示 -->

        <div class="empty" v-if="dataList.length == 0">
          <div class="tips">暂无数据</div>
        </div>
 </div>


    <!-- <div class="content">
      <div class="content_info" v-for="(item, i) in dataList" :key="i" @click="goDetail(item)">
        <div class="title">
          <div class="title_1">{{ item.examItems }}</div>
          <div class="title_2">{{ orderStatus[item.resultStatus] }}</div>
        </div>
        <div class="detail">患者：{{item.name}}</div>
        <div class="detail">报告时间：{{ item.reportDateTime }}</div>
      </div>
    </div> -->
    <van-popup v-model="showStartDate" round position="bottom">
      <van-datetime-picker
        type="date"
        title="开始日期"
        v-model="beforecurrentDate"
        :max-date="maxDate"
        @confirm="confirmStartTime"
        @cancel="showStartDate = false"
      />
    </van-popup>
    <van-popup v-model="showEndDate" round position="bottom">
      <van-datetime-picker
        type="date"
        title="结束日期"
        v-model="currentDate"
        :max-date="maxDate"
        @confirm="confirmEndTime"
        @cancel="showEndDate = false"
      />
    </van-popup>
  </div>
</template>
<script>
import { getList } from "@/api/checkListQuery";
import dateUtil from "@/utils/dateUtil.js";
import { setStore, getStore, removeStore } from "@/utils/localStorage.js";
import { Toast } from "vant";
export default {
  data() {
    return {
      active: 1,
      value: "",
      name: "",
      cardNo: '',
      dataList: [],
      resultInfo: {
        1: "未出",
        2: "未出",
        3: "未出",
        4: "已出",
        9: "其他",
      },
      beforecurrentDate: new Date(new Date().setMonth(new Date().getMonth() - 1)),
      currentDate: new Date(),
      startTime: getStore({ name: "reportstart" }) ,
      endTime: getStore({ name: "reportend" }) ,
      newStart: "" || 0,
      newEnd: "" || 0,
      maxDate: new Date(),
      showSelectUser: false,
      showStartDate: false,
      showEndDate: false,
    };
  },
  created() {
    window.scrollTo(0, 0)
    this.getdata();
  },
  mounted(){
    this.cardNo = this.$route.query.cardNo
  },
  methods: {
    getdata() {
      Toast.loading({
        message: "加载中...",
        forbidClick: true,
      });
      getList({
        patientId: this.$route.query.cardNo,
        startDate: this.startTime ,
        endDate: this.endTime ,
      }).then((res) => {
        console.log(this.startTime,'this.startTime')
         console.log(this.endTime,'this.endTime')
        if (res.data.code == "200") {
          this.dataList = res.data.data;
     
          this.name = res.data.data[0].name;
          // this.cardNo = res.data.data.cardNo;

          console.log( this.dataList,' this.dataList')
        }
      });
    },
    onClickLeft() {
      this.$router.push({ path: "/electronicQuery" });
    },
    goDetail(item) {
      console.log(item,'55')
      // return
      if (item.resultStatusCode == "4") {
        localStorage.setItem("itemData",JSON.stringify(item))
        this.$router.push({
          path: "/checkListQueryDetail",
          query: { cardNo: this.$route.query.cardNo},
        });
      } else {
        Toast("未出报告，请耐心等待")
      }
    },


    getStartTime(){
      document.activeElement.blur();
      this.showStartDate = true;
      this.startTime = "";
      if (this.startTime && this.endTime) {
        this.endTime = "";
      }
    },

    confirmStartTime(time){
      this.newStart = time.getTime();
      if (this.newEnd != 0) {
        if (this.newStart > this.newEnd) {
          Toast({
            type: "warning",
            message: "开始时间并不能大于结束时间",
          });
        } else if (this.newEnd - this.newStart > 86400000 * 180) {
          Toast({
            type: "warning",
            message: "时间跨度不能大于180天",
          });
        } else {
          this.startTime = dateUtil.formatDate3(time);
          this.showStartDate = false;
        }
      } else {
        this.startTime = dateUtil.formatDate3(time);
        this.showStartDate = false;
      }
    },

    getEndTime() {
      document.activeElement.blur();
      this.showEndDate = true;
      this.endTime = "";
      if (this.startTime && this.endTime) {
        this.startTime = "";
      }
    },
    confirmEndTime(time) {
      this.newEnd = time.getTime();

      if (this.newStart != 0) {
        if (this.newStart > this.newEnd) {
          Toast({
            type: "warning",
            message: "开始时间并不能大于结束时间",
          });
        } else if (this.newEnd - this.newStart > 86400000 * 180) {
          Toast({
            type: "warning",
            message: "时间跨度不能大于180天",
          });
        } else {
          this.endTime = dateUtil.formatDate3(time);
          this.showEndDate = false;
        }
      } else {
        this.endTime = dateUtil.formatDate3(time);
        this.showEndDate = false;
      }
    },
    sureSelectTime() {
      this.showEmpty = false;
      this.finishedText = "";
      this.list = [];

      let first = new Date(this.startTime).getTime();
      let last = new Date(this.endTime).getTime();
      if (last - first > 86400000 * 180) {
        Toast({
          type: "warning",
          message: "时间跨度不能大于180天",
        });
        return
      } else {
        this.getdata()
        setStore({ name: "reportstart", content: this.startTime });
        setStore({ name: "reportend", content: this.endTime });
      }
    }
  },
};
</script>
<style lang="less" scoped>
.wrap {
  .head_box {
    background-color: #f4f8fb;
    padding: 16px 16px 0 16px;
    box-sizing: border-box;
    // position: fixed;
    // top: 0;
    // left: 0;
    // right: 0;
    .box_info {
      border: 1px solid #8ebdfb;
      background: #ffffff;
      border-radius: 10px 10px 10px 10px;
      padding: 12px 10px;
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .box_left {
        display: flex;

        .box_left1 {
          margin-left: 12px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          .left1_1 {
            color: #333333;
            font-size: 15px;
            font-weight: bold;
          }

          .left1_2 {
            color: #999999;
            font-size: 15px;
          }
        }
      }
    }
  }

  .content {
      margin-top: 16px;
      padding: 0 16px;

      .top {
          font-size: 13pt;
          background: #fff;
          display: flex;
          justify-content: space-between;
          padding: 10px;
          border-radius: 10px 10px 0px 0px;
          border-bottom: 1px solid #DCDCDC;
      }
      .out{
        color: #2089F7;
      }
      .noout{
        color: #1AB29B;
      }

      .bottom {
        padding: 11px 11px 3px 11px;
        box-sizing: border-box;
        background: #ffffff;
        border-radius: 0px 0px 10px 10px;
        box-shadow: 0px 5px 3px 1px rgba(199, 225, 217, 0.26);

        .bottom_content {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 8px;
        }
      }
    }

    .empty {
      width: 152px;
      height: 122px;
      // background-image: url("@/assets/images/emptyOutpatientPayment.png");
      background-size: 100% 100%;
      margin: 80px auto;

      .tips {
        text-align: center;
        padding-top: 120px;
        font-size: 13px;
        font-weight: 400;
        color: #666666;
      }
    }
}

/deep/.van-cell-group {
  margin: 16px;
  border-radius: 10px;
}

/deep/.van-cell {
  border-radius: 10px;
}
input {
  width: 90pt;
  outline: none;
  border: none;
  font-size: 12pt;
  font-weight: 400;
  color: #333333;
  padding: 10px;
  background-color: #ffffff;
  border-radius: 45px;
  box-shadow: 0px 5px 3px 1px rgba(239, 247, 246, 1);
}
.leftInput {
  // margin-left: 20px;
  margin-right: 10px;
}
.sureSelect {
  margin-left: 12px;
  font-size: 13pt;
  font-weight: 400;
  color: #28d3d8;
  line-height: 28px;
  margin-top: 5px;
}
.selectTime {
  margin: 0 auto;
  display: flex;
  font-size: 13px;
  font-weight: 400;
  color: #333333;
  padding: 16px 16px 0;
}
.box{
  padding-bottom: 30px;
}
</style>
