import request from '@/router/axios';

export const getList = (data) => request({
  url: '/api/blade-handheld-hospital/outpatient/getCheckList',
  method: 'post',
  data,
});

//获取检查详情
export const getCheckDetail = (data) => request({
  url: '/api/blade-handheld-hospital/outpatient/getCheckDetail',
  method: 'post',
  data
})